.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.animate-modal-enter {
  opacity: 0;
}

.animate-modal-enter .modal-content {
  transform: scale(0);
}

.animate-modal-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.datepicker {
  z-index: 1000 !important;
}

.animate-modal-enter-active .modal-content {
  transform: scale(1);
  transition: transform 300ms;
}

.animate-modal-exit {
  opacity: 1;
  transition: opacity 300ms;
}

.animate-modal-exit-active {
  opacity: 0;
}

.animate-modal-exit .modal-content {
  transform: scale(1);
}

.animate-modal-exit-active .modal-content {
  transform: scale(0);
  transition: transform 300ms;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.h-90 {
  height: 90% !important;
}

/* Messaging tab */
.rt-counter {
  display: flex;
  position: relative;
  top: 1.5rem;
}

/* End  Messaging tab */
/* remove modal button*/
button.btn-r-all {
  width: 100%;
}

button.btn-r-all i {
  background: transparent;
  color: white;
  display: inline;
}

/**/
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}