/* ESTILOS DEL PREVIEW */
.wrapper {
  justify-content: center;
  align-content: center;
  display: flex;
  position:relative;
}

.desktop {
  box-sizing: content-box;
  border-radius: 35px;
  border: 2px solid #00b08c;
  padding-top: 35px;
  padding-bottom:  35px;
  transition: all 0.3s ease-in;
}

.phone {
  box-sizing: content-box;
  border-radius: 35px;
  border: 2px solid #00b08c;
  padding-top: 54px;
  padding-bottom: 76px;
  transition: all 0.3s ease-in;
}

.phone:after {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #00b08c;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  /*content: "";*/
}

.wrapper-mobile {
  width: 340px;
  height: 585px;
}
.wrapper-tablet {
  width: 618px;
  height: 757px;
}
.wrapper-desktop {
  width: 100%;
  height:757px;
}